
import { defineComponent } from "vue";
import { auth } from "@/firebase";
import { useMediaQuery } from "@vueuse/core";
import { mapGetters, mapMutations } from "vuex";
import { MutationTypes } from "@/store/mutations/types";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";

export default defineComponent({
  name: "DashboardIndex",
  components: { LocaleSwitcher },
  setup() {
    const isMobile = useMediaQuery("(max-width: 768px)");
    return {
      isMobile,
    };
  },
  data() {
    return {
      isCollapsed: false,
      displayName: auth.currentUser?.displayName ?? auth.currentUser?.email,
    };
  },
  methods: {
    ...mapMutations({
      resetStore: MutationTypes.RESET_STORE,
    }),
    signOut() {
      auth.signOut();
      this.resetStore();
      this.$router.replace(
        `/${this.$route.params.locale ?? this.$i18n.fallbackLocale}`
      );
    },
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
});
