
import { defineComponent } from "vue";

export default defineComponent({
  name: "LocaleSwitcher",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    language: {
      get(): string {
        return this.$i18n.locale;
      },
      set(locale: any) {
        if (locale !== this.$i18n.locale) this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: { locale } });
        this.$router.push(to);
      },
    },
    niceNames() {
      const niceNames: Record<string, string> = {
        fi: "Suomi",
        sv: "Svenska",
      };
      return niceNames;
    },
  },
});
