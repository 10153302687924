import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "locale-changer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_el_select, {
      size: _ctx.small ? 'mini' : 'large',
      modelValue: _ctx.language,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.language = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (locale) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: `locale-${locale}`,
            value: locale,
            label: _ctx.niceNames[locale]
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.niceNames[locale]), 1)
            ]),
            _: 2
          }, 1032, ["value", "label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["size", "modelValue"])
  ]))
}